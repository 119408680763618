<template>
    <span :class="`text-${chipColor(params.value)}`">{{ colorText(params.value) }}</span>
</template>

<script>
export default {
  name: 'CellRendererPriority',
  data: () => ({
    priorities: [
      {text: "Urgent", value:1, color: 'danger'},
      {text: "Major", value:2, color: 'orange'},
      {text: "Minor", value:3, color: 'warning'},
    ],
  }),
  computed: {
    chipColor () {
      return (value) => {
        return this.priorities.find(s => s.value == value).color;
      }
    },
    colorText () {
      return (value) => {
        return this.priorities.find(s => s.value == value).text;
      }
    }
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
