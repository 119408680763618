<template>
  <span @click.prevent="editRecord">
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>{{ statusText(params.value) }}</span>
    </vs-chip>
  </span>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data: () => ({
    status: [
      {text: "Finish", value:0, color: 'primary'},
      {text: "Approved", value:1, color: 'success'},
      {text: "Waiting Approval", value:2, color: 'warning'},
      {text: "Reject", value:3, color: 'danger'},
      {text: "Cancel", value:4, color: 'danger'},
    ]
  }),
  methods: {
    editRecord () {
      if (this.params.data.status != 0) {
        this.params.editStatus(this.params.data);
      } else {
        this.params.showRequest(this.params.data);
      }
    },
    test () {
      alert('test');
    }
  },
  computed: {
    chipColor () {
      return (value) => {
        return this.status.find(s => s.value == value).color;
      }
    },
    statusText () {
      return (value) => {
        return this.status.find(s => s.value == value).text;
      }
    }
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
  &.vs-chip-primary {
    background: rgba(var(--vs-primary),.15);
    color: rgba(var(--vs-primary),1) !important;
    font-weight: 500;
  }
}
</style>
