<template>
  <div>
    <div class="flex items-center" v-if="params.value">
      <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ jenis.find(j => j.id == params.value).name }}</router-link>
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererCar',
  data: () => ({
    jenis: [
      {name: "-", id:0},
      {name: "Proton BOLD (B 1437 PRY)", id:1},
      {name: "Proton PRIME (B 149 AST)", id:2},
      {name: "Taxy", id:3},
      {name: "Hyundai SANTA FE (B 152 AST)", id:4},
      {name: "Toyota RUSH (B 2585 PFT)", id:5},
      {name: "Hyundai Stargazer (B 2937 PZH)", id:6},
    ],
  }),
  computed: {
    url () {
      return '/'

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    }
  }
}
</script>
