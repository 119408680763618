const vehicles = [
  {id: 0, name: "-", available: 1},
  {id: 2, name: "Proton PRIME (B 149 AST)", available: 1},
  {id: 3, name: "Taxy", available: 1},
  {id: 4, name: "Hyundai SANTA FE (B 152 AST)", available: 1},
  {id: 5, name: "Toyota RUSH (B 2585 PFT)", available: 1},
  {id: 6, name: "Hyundai Stargazer (B 2937 PZH)", available: 1},
  {id: 1, name: "Proton BOLD (B 1437 PRY)", available: 1},
];

export default vehicles;
